<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="module">
    <div class="m_left">
      <div class="page-title">院系/专业/班级</div>
      <sy-tree @nodeclick="select"></sy-tree>
    </div>
    <div ref="m_right" class="m_right">
      <div class="page-title">贷款信息</div>
      <!--==============================工具==================================-->
      <div class="page-tool">
        <div>
          <span class="mr5 fcolor42">审核状态:</span>
          <el-select v-model="shzt" size="small" clearable>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="退回" :value="2"></el-option>
            <el-option label="已通过" :value="3"></el-option>
          </el-select>
          <el-input
            v-model="keyword"
            placeholder="请输入学号/姓名/考生号"
            size="small"
            style="width: 200px; margin-left: 10px"
          ></el-input>
          <el-button
            class="ml10"
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getDataPageList"
            >搜索</el-button
          >
        </div>

        <el-button-group>
          <el-button
            size="small"
            icon="el-icon-refresh"
            @click="reload()"
          ></el-button>
          <el-button size="small" icon="el-icon-box" @click="shenpi"
            >审批</el-button
          >
          <el-button size="small" icon="el-icon-download" @click="showExport"
            >导出</el-button
          >
        </el-button-group>
      </div>
      <!--=============================表格========================================-->
      <div class="c_table">
        <el-table
          v-loading="tableloading"
          :data="dataPageList"
          :height="windowHeight - 270"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          @current-change="currentRowChange"
          highlight-current-row
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <el-table-column prop="ksh" label="考生号" width="200" fixed>
          </el-table-column>
          <el-table-column prop="xh" label="学号" width="150" fixed>
          </el-table-column>
          <el-table-column prop="xm" label="姓名" width="100" fixed>
          </el-table-column>
          <el-table-column prop="bmmc" label="院系" width="200">
          </el-table-column>
          <el-table-column prop="zymc" label="专业" width="200">
          </el-table-column>
          <el-table-column prop="bjmc" label="班级" width="200">
          </el-table-column>
          <el-table-column prop="nj" label="年级" width="100">
          </el-table-column>
          <el-table-column prop="xsxn" label="新生学年" width="200">
          </el-table-column>
          <el-table-column prop="zxdkbm" label="助学贷款编码" width="150">
          </el-table-column>
          <el-table-column prop="zxdkje" label="助学贷款金额" width="120">
          </el-table-column>
          <el-table-column prop="tjsj" label="提交时间" width="200">
            <template slot-scope="scop">
              {{ dateFormat(scop.row.tjsj) }}
            </template>
          </el-table-column>
          <el-table-column prop="shzt" label="审核状态" width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.shzt === 3" type="success">通过</el-tag>
              <el-tag v-if="scope.row.shzt === 2" type="danger">打回</el-tag>
              <el-tag v-if="scope.row.shzt === 1" type="primary">待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="shsm" label="审核说明" width="150">
          </el-table-column>
          <el-table-column prop="shr" label="审核人" width="100">
          </el-table-column>
          <el-table-column prop="shsj" label="审核时间" width="200">
            <template slot-scope="scop">
              {{ dateFormat(scop.row.shsj) }}
            </template>
          </el-table-column>
        </el-table>
        <!--=======================================分页================================================-->
        <div class="pagebox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination.records"
          >
          </el-pagination>
        </div>
      </div>
      <!--审批-->
      <el-dialog
        title="助学贷款审批"
        :visible.sync="dialogSPVisible"
        width="70%"
        :close-on-click-modal="false"
        top="5vh"
      >
        <el-scrollbar style="height: 550px" class="myscrollbar">
          <el-form>
            <div class="form-item-group">
              <i class="fa fa-window-restore"></i>
              <div>贷款信息</div>
            </div>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="考生号" label-width="120px">
                  <el-input v-model="info.ksh" readonly size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="学号" label-width="120px">
                  <el-input v-model="info.xh" readonly size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="姓名" label-width="120px">
                  <el-input v-model="info.xm" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="院系" label-width="120px">
                  <el-input v-model="info.bmmc" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="专业" label-width="120px">
                  <el-input v-model="info.zymc" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="班级" label-width="120px">
                  <el-input v-model="info.bjmc" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="年级" label-width="120px">
                  <el-input v-model="info.nj" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="新生学年" label-width="120px">
                  <el-input v-model="info.xsxn" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="助学贷款编码" label-width="120px">
                  <el-input v-model="info.zxdkbm" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="助学贷款金额" label-width="120px">
                  <el-input v-model="info.zxdkje" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提交时间" label-width="120px">
                  <el-input v-model="info.tjsj" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="附件" label-width="120px">
                  <div class="demo-image__preview">
                    <el-image
                      v-for="item in info.fj"
                      :key="item.id"
                      style="width: 100px; height: 100px; margin-right: 10px"
                      :src="baseUrl + item.filepath"
                      :preview-src-list="new Array(baseUrl + item.filepath)"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form :model="info" ref="formRef" :rules="forminfoRules">
            <div class="form-item-group">
              <i class="fa fa-window-restore"></i>
              <div>审核</div>
            </div>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="审核说明" label-width="120px" prop="shsm">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    v-model="info.shsm"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="审批" label-width="120px" prop="shzt">
                  <el-radio v-model="info.shzt" :label="3">审核通过</el-radio>
                  <el-radio v-model="info.shzt" :label="2">审核打回</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSPVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm">确 定</el-button>
        </div>
      </el-dialog>
      <!--导出日志弹出框-->
      <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
        <div>
          <el-checkbox
            v-model="excelForm.KSH"
            label="考生号"
            border
            style="width: 130px; margin-top: 10px; margin-left: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XH"
            label="学号"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XM"
            label="姓名"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.BMMC"
            label="院系"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ZYMC"
            label="专业"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.NJ"
            label="年级"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.XSXN"
            label="新生学年"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ZXDKBM"
            label="助学贷款编码"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.ZXDKJE"
            label="助学贷款金额"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.TJSJ"
            label="提交时间"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.SHZT"
            label="审核状态"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.SHSM"
            label="审核说明"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
          <el-checkbox
            v-model="excelForm.SHR"
            label="审核人"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>

          <el-checkbox
            v-model="excelForm.SHSJ"
            label="审核时间"
            border
            style="width: 130px; margin-top: 10px"
          ></el-checkbox>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogExcelVisible = false">取 消</el-button>
          <el-button type="primary" @click="exportToExcel">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import bmtree from '../../components/tree/bm_zy_bj.vue'
import { getAuthorizeButtonColumnList } from '../../api/system'
import {
  getDKXXPageList,
  exportZXDKExcel,
  saveDKSPInfo,
  GetDKXXFormData,
} from '../../api/yingxin'
import moment from 'moment'
export default {
  components: {
    'sy-tree': bmtree,
  },
  data() {
    return {
      baseUrl: 'https://apicdau.cdau.edu.cn',
      info: {},
      dialogSPVisible: false,
      buttonauth: [],
      tableloading: false,
      dialogExcelVisible: false,
      dataPageList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      keyValue: '',
      keyword: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      shzt: null,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      excelForm: {
        KSH: true,
        XH: true,
        XM: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        NJ: true,
        XSXN: true,
        ZXDKBM: true,
        ZXDKJE: true,
        TJSJ: true,
        SHZT: true,
        SHSM: true,
        SHR: true,
        SHSJ: true,
      },
      // 表单验证规则对象
      forminfoRules: {
        shsm: [{ required: true, message: '请输入审核说明', trigger: 'blur' }],
        shzt: [{ required: true, message: '请选择审核状态', trigger: 'blur' }],
      },
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    select(node) {
      this.bmbh = ''
      this.zybh = ''
      this.bjbh = ''
      if (node.type === 'bm') {
        this.bmbh = node.value
      } else if (node.type === 'zy') {
        this.zybh = node.value
      } else if (node.type === 'bj') {
        this.bjbh = node.value
      }
      this.getDataPageList()
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getDataPageList() {
      this.tableloading = true
      getDKXXPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          isbd: this.isbd,
          isjf: this.isjf,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
          shzt: this.shzt,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    shenpi() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        GetDKXXFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.info = res.data
            this.info.tjsj = this.dateFormat(this.info.tjsj)
            this.dialogSPVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          if (this.info.shzt !== 2 && this.info.shzt !== 3) {
            this.$message.error('请选择审批状态！')
            return false
          }
          var spinfo = {
            ID: this.info.id,
            KSH: this.info.ksh,
            SHZT: this.info.shzt,
            SHSM: this.info.shsm,
            ZXDKJE: this.info.zxdkje,
          }
          saveDKSPInfo(spinfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogSPVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    reload() {
      this.$router.go(0)
    },
    showLook(id) {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.KSH) {
        exportFiles.push({ name: 'KSH', label: '考生号' })
      }
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.NJ) {
        exportFiles.push({ name: 'NJ', label: '年级' })
      }
      if (this.excelForm.XSXN) {
        exportFiles.push({ name: 'XSXN', label: '新生学年' })
      }
      if (this.excelForm.ZXDKBM) {
        exportFiles.push({ name: 'ZXDKBM', label: '助学贷款编码' })
      }
      if (this.excelForm.ZXDKJE) {
        exportFiles.push({ name: 'ZXDKJE', label: '助学贷款金额' })
      }
      if (this.excelForm.TJSJ) {
        exportFiles.push({ name: 'TJSJ', label: '提交时间' })
      }
      if (this.excelForm.SHZT) {
        exportFiles.push({ name: 'SHZT', label: '审核状态' })
      }
      if (this.excelForm.SHSM) {
        exportFiles.push({ name: 'SHSM', label: '审核说明' })
      }
      if (this.excelForm.SHR) {
        exportFiles.push({ name: 'SHR', label: '审核人' })
      }
      if (this.excelForm.SHSJ) {
        exportFiles.push({ name: 'SHSJ', label: '审核时间' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportZXDKExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
          shzt: this.shzt,
        }),
        fileName: '新生贷款信息',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    // 时间格式化
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.module {
  position: relative;
  padding-left: 210px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.m_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
}
.m_right {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.group-title {
  margin-left: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #409eff;
  font-size: 18px;
  padding-left: 5px;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
